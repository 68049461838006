import { Chip as MuiChip, Dialog } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { G, S } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import scrollbarStyles from "../../theme/scrollbarStyles";

export const StyledAgentBox = styled("div")(() => ({
  width: "100%",
  display: "grid",
  gridGap: "0px 16px",
  alignItems: "center",
  gridTemplateColumns: "1fr auto auto",
}));

export const StyledSignerBox = styled("div")(() => ({
  width: "100%",
  display: "grid",
  gridGap: "0px 16px",
  alignItems: "center",
  gridTemplateColumns: "1fr auto auto",
}));

export const Span = styled("span")(({ theme }) => ({
  display: "flex",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(19),
  gap: theme.spacing(0.5),
  alignItems: "center",
  color: theme.palette.text.secondary,
}));

export const Label = styled("label")(({ theme }) => ({
  alignItems: "center",
  color: theme.palette.text.primary,
  display: "flex",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  gap: theme.spacing(0.5),
  lineHeight: theme.typography.pxToRem(19),
  '&[data-disabled="true"]': {
    color: theme.palette.text.disabled,
  },
}));

export const FileLabel = styled("label")(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.spacing(0.5),
  cursor: "pointer",
  display: "flex",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  justifyContent: "center",
  minHeight: "33vh",
  minWidth: "33vh",
  padding: theme.spacing(1),
  position: "relative",
  transition: "box-shadow 0.1s ease-in-out",
  '& input[type="file"]': {
    opacity: 0,
    width: 0,
    height: 0,
  },
  "&.drag-over": {
    boxShadow: "0 0 0 2px #1096E7",
  },
}));

export const SelectButton = styled("button")(({ theme }) => ({
  background: "none",
  border: "none",
  borderRadius: theme.spacing(1),
  boxShadow: "0 0 0 1px #D2D2D2",
  color: theme.palette.text.primary,
  cursor: "pointer",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  height: theme.spacing(8),
  transition: "box-shadow 0.1s ease-in-out, filter 0.1s ease-in-out",
  width: "100%",
  minWidth: 132,
  "&.selected, &:disabled.selected, &:disabled:hover.selected": {
    boxShadow: "0 0 0 2px #1096E7",
    color: theme.palette.text.primary,
  },
  "&:hover": {
    boxShadow: "0 0 0 2px #1096E7",
  },
  "&:disabled, &:disabled:hover": {
    cursor: "initial",
    color: theme.palette.text.secondary,
    boxShadow: "0 0 0 1px #D2D2D2",
  },
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  borderRadius: 10,
  fontFamily: theme.typography.interFontStack,
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(12),
  background: "#DBDBDB",
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-scrollPaper": {
    alignItems: "flex-start",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  "& .MuiPaper-root": {
    border: "0.65px solid #CCC",
    overflowX: "hidden",
    backgroundColor: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #F9F9F9",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: { width: "100vw" },
    [theme.breakpoints.up("md")]: { width: 800, minWidth: "632px" },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
  "& .MuiDialog-paperScrollPaper::-webkit-scrollbar-track": {
    borderRadius: theme.spacing(0.5),
  },
}));

export const StyledSmallDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(3),
    border: "0.65px solid #CCC",
    overflowX: "hidden",
    backgroundColor: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #F9F9F9",
    borderRadius: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
    maxWidth: "60ch",
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
  "& .MuiDialog-paperScrollPaper::-webkit-scrollbar-track": {
    borderRadius: theme.spacing(0.5),
  },
}));

export const Button = styled("button")(({ theme }) => ({
  background:
    "linear-gradient(0deg, #1096E7 0%, #1096E7 100%), linear-gradient(0deg, #FAFCFF 0%, #FAFCFF 100%), #F9F9F9;",
  border: "none",
  borderRadius: theme.spacing(1),
  color: theme.palette.getContrastText("#1096E7"),
  cursor: "pointer",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  minHeight: 42,
  minWidth: 88,
  padding: theme.spacing(1, 3),
  boxShadow: theme.boxShadow.buttonDefault,
  transition: "box-shadow 0.1s ease-in-out, filter 0.1s ease-in-out",
  "&:hover": {
    boxShadow: theme.boxShadow.buttonHover,
    filter: "brightness(1.05)",
  },
  "&:disabled, &:hover:disabled": {
    background: theme.palette.grey[300],
    color: theme.palette.text.disabled,
    boxShadow: "none",
    filter: "none",
    cursor: "initial",
  },
  "&.small": {
    minHeight: 32,
    minWidth: 64,
  },
}));

export const TextButton = styled("button")(({ theme }) => ({
  background: "none",
  border: 0,
  margin: 0,
  padding: theme.spacing(1, 0),
  cursor: "pointer",
  fontFamily: theme.typography.interFontStack,
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
  color: "#1096E7",
  textAlign: "left",
  justifyContent: "flex-start",
  alignItems: "center",
  display: "flex",
  width: "fit-content",
  minWidth: "fit-content",
  boxShadow: "none",
  "&:hover, &:focus": {
    background: "none",
    textShadow: "0 0 2px rgba(0, 0, 0, 0.1)",
  },
  "&.plain": {
    padding: 0,
    color: theme.palette.text.secondary,
    font: "inherit",
    fontSize: theme.typography.pxToRem(12),
    textDecoration: "underline",
  },
  "&[disabled], &[disabled]:hover": {
    color: theme.palette.text.disabled,
    cursor: "initial",
  },
}));

const StyledText = styled("span")(({ theme }) => ({
  width: "100%",
  textAlign: "right",
  display: "block",
  height: 16,
  lineHeight: "16px",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(10),
  color: theme.palette.text.secondary,
}));

export function InputLengthStatus({ value, length }) {
  return (
    <StyledText>
      {!G.isString(value) || S.isEmpty(value)
        ? ""
        : `${S.length(value)} / ${length}`}
    </StyledText>
  );
}

InputLengthStatus.propTypes = {
  value: PropTypes.string,
  length: PropTypes.number,
};

export const StyledDimensions = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(1),
  margin: theme.spacing(2, 0),
  background: theme.palette.grey[100],
  width: "fit-content",
  minWidth: 452,
}));

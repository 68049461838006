import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Form } from "../../theme";
import { useChanges } from "./state";

function Agent({ agent, removeAgent, updateAgent }) {
  const nameParts = [agent.firstName, agent.lastName].filter(Boolean);
  const [amount, setAmount] = React.useState(agent.splitAmount / 100);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changes, setChanges] = useChanges();

  const handleEdit = async () => {
    if (!isEditing) {
      setChanges(D.set(agent.userId, true));
      setIsEditing(true);
      return;
    }

    setIsLoading(true);

    const newSplitAmount = Number(amount) * 100;
    const isSuccess = await updateAgent(
      D.merge(agent, { splitAmount: newSplitAmount }),
    );

    setIsLoading(false);

    if (!isSuccess) {
      return;
    }

    setChanges(D.deleteKey(agent.userId));
    setIsEditing(false);
  };

  return (
    <Box
      display="grid"
      gridGap="8px"
      gridTemplateColumns="minmax(220px, 1fr) 92px auto auto"
      key={agent.userId}
    >
      <Box>
        <Typography variant="body2">
          {nameParts.length ? nameParts.join(" ") : "No Name"}
          {changes[agent.userId] && (
            <Typography
              component="span"
              variant="caption"
              color="secondary"
              style={{ gridColumn: "span 4" }}
            >
              {" "}
              (Unsaved Changes)
            </Typography>
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {agent.email}
        </Typography>
      </Box>

      <Form.Input
        disabled={!isEditing || isLoading}
        InputProps={{ startAdornment: "$" }}
        inputProps={{ inputMode: "numeric" }}
        label="Amount"
        shrink
        size="small"
        value={amount}
        variant="outlined"
        onChange={(evt) => {
          setAmount(evt.target.value);
        }}
      />

      <Button
        disabled={isLoading}
        onClick={handleEdit}
        size="small"
        variant="outlined"
        color={isEditing ? "secondary" : "default"}
        style={{ width: 64 }}
      >
        {isLoading && <CircularProgress size={20} />}
        {!isLoading && (isEditing ? "Save" : "Edit")}
      </Button>

      <Box display="flex" alignItems="center">
        <IconButton
          size="small"
          onClick={(evt) => {
            setAnchorEl(evt.currentTarget);
          }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
      </Box>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          disabled={isEditing}
          onClick={() => {
            setChanges(D.set(agent.userId, true));

            removeAgent(agent.userId).then(() => {
              setChanges(D.deleteKey(agent.userId));
            });
          }}
          size="small"
          variant="outlined"
        >
          Remove
        </MenuItem>
      </Menu>
    </Box>
  );
}

Agent.propTypes = {
  agent: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    splitAmount: PropTypes.number.isRequired,
  }).isRequired,
  removeAgent: PropTypes.func.isRequired,
  updateAgent: PropTypes.func.isRequired,
};

export default function Agents({ agents, removeAgent, updateAgent }) {
  if (!agents.length) {
    return (
      <Typography variant="body2" color="textSecondary">
        No agents assigned.
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gridGap="16px">
      {agents.map((agent) => (
        <Agent
          agent={agent}
          key={agent.userId}
          removeAgent={removeAgent}
          updateAgent={updateAgent}
        />
      ))}
    </Box>
  );
}

Agents.propTypes = {
  agents: PropTypes.array.isRequired,
  removeAgent: PropTypes.func.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  updateAgent: PropTypes.func.isRequired,
};

import { Box, Switch } from "@material-ui/core";
import PropTypes from "prop-types";
import { Form, InfoTooltip } from "../../../theme";
import * as styles from "../styles";
import { format } from "../utils";

function descriptionFor(agent) {
  if (agent.amount === 0) {
    return "This agent has been disabled and will not receive a cut of this product. To enable, click the plus icon.";
  }

  return agent.description;
}

export default function AgentSplit({
  agent,
  currentUser,
  handleAddAgent,
  handleRemoveAgent,
}) {
  return (
    <styles.StyledAgentBox>
      <styles.Label data-disabled={agent.amount === 0}>
        {agent.title}
        <InfoTooltip
          title={agent.title}
          content={descriptionFor(agent)}
          size="small"
        />
      </styles.Label>

      <Form.Input
        className="grey-filled"
        value={format(agent.amount ?? 0)}
        inputProps={{ size: 2 }}
        InputProps={{ startAdornment: "$" }}
        size="small"
        type="number"
        disabled
      />

      {currentUser.isAdmin && (
        <Box minWidth={42} justifySelf="center">
          <Switch
            checked={agent.amount > 0}
            onChange={() => {
              if (agent.amount > 0) {
                handleRemoveAgent(agent);
              } else {
                handleAddAgent(agent);
              }
            }}
          />
        </Box>
      )}
    </styles.StyledAgentBox>
  );
}

AgentSplit.propTypes = {
  agent: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleAddAgent: PropTypes.func.isRequired,
  handleRemoveAgent: PropTypes.func.isRequired,
};

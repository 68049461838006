import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product } from "../../../domain";
import InfluencerLinks from "../../InfluencerLinks";
import { ProductButton, ProductImage, ProductText } from "../../Product";

const ProductContainer = React.memo(function ProductContainer({
  product,
  influencer,
  onOpen,
}) {
  const handleOpen = React.useCallback(() => {
    onOpen(product);
  }, [product, onOpen]);

  return (
    <Grid key={product.productId} container direction="column">
      <ProductButton onClick={handleOpen}>
        <ProductImage product={product} isStorefront />

        <ProductText product={product} isStorefront />
      </ProductButton>

      {influencer.isGroupShop && (
        <InfluencerLinks influencers={product.fulfillers ?? [influencer]} />
      )}
    </Grid>
  );
});

ProductContainer.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
  onOpen: PropTypes.func,
};

export default ProductContainer;

import { Dialog } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
    },
    width: "100%",
  },
}));

export default StyledDialog;

import { Box } from "@material-ui/core";
import { D, pipe } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../../domain";
import { useInfluencerRoute } from "../../../hooks";
import {
  createParser,
  useStateFromParams,
} from "../../../hooks/useStateFromParams";
import { ErrorUI, GridPaginator, LoadingUI } from "../../../theme";
import SignedTypeSelect from "../../SignedTypeSelect";
import SignerSelect from "../../SignerSelect";
import ProductContainer from "./ProductContainer";

function toMap(acc, value) {
  acc[value] = true;
  return acc;
}

const parsers = pipe(
  {},
  D.merge(
    createParser({
      name: "signedTypes",
      key: "st",
      getter: (paramValue) =>
        paramValue?.split(",")?.filter(SignType.enum.has)?.reduce(toMap, {}) ??
        {},
    }),
  ),
  D.merge(
    createParser({
      name: "signers",
      key: "si",
      getter: (paramValue) =>
        paramValue?.split(",")?.filter(Boolean)?.reduce(toMap, {}) ?? {},
    }),
  ),
);

const ShopProducts = React.memo(function ShopProducts({ setProduct }) {
  const { influencer, isLoading, isError } = useInfluencerRoute();
  const [state, setState] = useStateFromParams(parsers);

  const distinctSigners = React.useMemo(() => {
    if (!influencer?.products) {
      return {};
    }

    return influencer.products.reduce((result, product) => {
      const { fulfillers } = product;
      if (!Array.isArray(fulfillers)) {
        return result;
      }

      fulfillers.forEach((fulfiller) => {
        if (!result[fulfiller.influencerId]) {
          result[fulfiller.influencerId] = {
            influencerId: fulfiller.influencerId,
            name: fulfiller.name,
            avatarUrl: fulfiller.avatarUrl,
          };
        }
      });

      return result;
    }, {});
  }, [influencer.products]);

  const products = React.useMemo(() => {
    if (!influencer?.products) {
      return [];
    }

    const hasFilters =
      !D.isEmpty(state.signedTypes) || !D.isEmpty(state.signers);

    if (!hasFilters) {
      return influencer.products.map((product) => (
        <ProductContainer
          key={product.productId}
          product={product}
          onOpen={setProduct}
          influencer={influencer}
        />
      ));
    }

    return influencer.products.reduce((result, product) => {
      const notFilteringBySignedType = D.isEmpty(state.signedTypes);
      const notFilteringBySigner = D.isEmpty(state.signers);

      const hasSignedType = Boolean(state.signedTypes[product.signedType]);
      const hasSigner = Boolean(
        product.fulfillers?.some?.((fulfiller) =>
          Boolean(state.signers[fulfiller.influencerId]),
        ),
      );

      if (
        (notFilteringBySignedType || hasSignedType) &&
        (notFilteringBySigner || hasSigner)
      ) {
        result.push(
          <ProductContainer
            key={product.productId}
            product={product}
            onOpen={setProduct}
            influencer={influencer}
          />,
        );
      }
      return result;
    }, []);
  }, [influencer, state, setProduct]);

  const setSigners = React.useCallback(
    (signers) => {
      setState(D.merge({ signers }));
    },
    [setState],
  );

  const setSignedTypes = React.useCallback(
    (signedTypes) => {
      setState(D.merge({ signedTypes }));
    },
    [setState],
  );

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop products" />;
  }

  return (
    <Box pb={8} pt={4}>
      <Box mb={4}>
        <SignerSelect
          initialState={state.signers}
          onChange={setSigners}
          options={distinctSigners}
        />

        <SignedTypeSelect
          initialState={state.signedTypes}
          onChange={setSignedTypes}
        />
      </Box>

      {products.length > 0 && (
        <GridPaginator useObserver pageItems={products} pageSize={20} />
      )}
    </Box>
  );
});

ShopProducts.propTypes = {
  signType: PropTypes.string,
  setProduct: PropTypes.func,
};

export default ShopProducts;

import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Influencer, Product, withIncludes } from "../../../domain";
import { useInfluencers } from "../../../hooks";
import * as constants from "../constants";
import SectionExpanded from "./SectionExpanded";
import ShopsSlider from "./ShopsSlider";

function enricherFor(type) {
  if (type === "influencer") {
    return (results) => results.map(Influencer.from);
  }

  return (results) => results.map((obj) => withIncludes(Product.from(obj)));
}

// eslint-disable-next-line complexity
function Section({
  section: { name, href, title, type, look },
  isDark = true,
  isMobile,
  updateFavouriteStatus,
}) {
  const { ref, inView } = useInView({ threshold: 0.01 });
  const boxEl = React.useRef(null);

  const { results, isLoading, refresh } = useInfluencers(
    inView,
    name,
    enricherFor(type),
  );

  const wrappedUpdateFavoriteStatus = React.useCallback(
    (influencer, callback) => {
      updateFavouriteStatus(influencer, (isFavourite) => {
        if (!isFavourite && name.startsWith("favourite")) {
          refresh();
        } else {
          return callback(isFavourite);
        }
      });
    },
    [updateFavouriteStatus, name, refresh],
  );

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [boxWidth, setBoxWidth] = React.useState(0);

  const updateWidth = React.useCallback(() => {
    if (!boxEl.current) {
      return;
    }

    setBoxWidth(() => {
      const { width } = boxEl.current.getBoundingClientRect();
      return width;
    });
  }, [boxEl]);

  React.useEffect(() => {
    const update = updateWidth;

    window.addEventListener("resize", update);

    update();

    return () => {
      window.removeEventListener("resize", update);
    };
  }, [isExpanded, updateWidth]);

  const { columnGap, numberPerRow } = gridValuesFor({
    boxWidth,
    isMobile,
    look,
  });

  if (isLoading && !results?.length) {
    const cardWidth = constants.cardWidthFor({ isMobile, look });
    const cardHeight = constants.cardHeightFor({ isMobile, look });

    return (
      <div className={clsx("home-page__sections__section", look)} ref={boxEl}>
        <div className="flex-between" ref={ref}>
          <h3
            className={clsx(
              "home-page__sections__section-title",
              isDark && "dark",
            )}
          >
            {title}
          </h3>
        </div>

        <div
          className={clsx(
            "shops-slider__grid",
            "shops-slider__grid-loading",
            look,
          )}
          style={{ gap: columnGap }}
        >
          {Array.from({ length: numberPerRow }).map((_, i) => (
            <Skeleton
              key={i}
              variant="rect"
              width={cardWidth}
              height={cardHeight}
            />
          ))}
        </div>
      </div>
    );
  }

  if (!isLoading && !results?.length) {
    return null;
  }

  const showExpandButton = results?.length && numberPerRow < results.length;

  return (
    <div className={clsx("home-page__sections__section", look)} ref={boxEl}>
      <div className="flex-between" ref={ref}>
        <h3
          className={clsx(
            "home-page__sections__section-title",
            isDark && "dark",
          )}
        >
          {!!href && <Link to={href}>{title}</Link>}
          {!href && title}
        </h3>

        {Boolean(href) && (
          <Link
            className="text-button"
            style={{ color: isDark ? "white" : "#000" }}
            to={href}
          >
            View All
          </Link>
        )}

        {!href && showExpandButton && (
          <button
            className="text-button"
            style={{ color: isDark ? "white" : "#000" }}
            onClick={() => setIsExpanded((s) => !s)}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </button>
        )}
      </div>

      {!isExpanded && (
        <ShopsSlider
          products={type === constants.PRODUCT_TYPE ? results : undefined}
          influencers={type === constants.INFLUENCER_TYPE ? results : undefined}
          updateFavouriteStatus={
            updateFavouriteStatus ? wrappedUpdateFavoriteStatus : undefined
          }
          look={look}
          isMobile={isMobile}
          isDark={isDark}
          numberPerRow={numberPerRow}
          updateWidth={updateWidth}
        />
      )}

      {isExpanded && (
        <SectionExpanded
          products={type === constants.PRODUCT_TYPE ? results : undefined}
          influencers={type === constants.INFLUENCER_TYPE ? results : undefined}
          updateFavouriteStatus={
            updateFavouriteStatus ? wrappedUpdateFavoriteStatus : undefined
          }
          numberPerRow={numberPerRow}
          columnGap={columnGap}
          isMobile={isMobile}
          isDark={isDark}
          look={look}
        />
      )}
    </div>
  );
}

Section.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.instanceOf(Influencer)),
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  section: PropTypes.shape({
    type: PropTypes.oneOf([constants.PRODUCT_TYPE, constants.INFLUENCER_TYPE]),
    name: PropTypes.string,
    title: PropTypes.string,
    look: PropTypes.oneOf([
      constants.LOOK_TALL,
      constants.LOOK_WIDE,
      constants.LOOK_PRODUCT,
    ]),
    href: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDark: PropTypes.bool,
  updateFavouriteStatus: PropTypes.func,
};

function gridValuesFor({ boxWidth, isMobile, look }) {
  const cardWidth =
    constants.cardWidthFor({ isMobile, look }) +
    constants.minGridGapFor({ isMobile });

  let numberPerRow = Math.floor(boxWidth / cardWidth);
  let gapRemainder = boxWidth - numberPerRow * cardWidth;

  if (gapRemainder / numberPerRow < constants.minGridGapFor({ isMobile })) {
    numberPerRow -= 1;
    gapRemainder = boxWidth - numberPerRow * cardWidth;
  }

  const columnGap = (boxWidth - numberPerRow * cardWidth) / numberPerRow;

  return {
    numberPerRow,
    columnGap: Number.isNaN(columnGap) ? 0 : columnGap,
  };
}

export default React.memo(Section);

import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { TinyAvatar } from "../../theme";

const StyledButton = styled("button")(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.grey[200],
  border: "none",
  cursor: "pointer",
  display: "flex",
  margin: 0,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  textAlign: "left",
  gap: theme.spacing(1),
  transition: "background 0.2s ease-in-out",
  "& span": {
    ...theme.typography.body2,
    fontWeight: 600,
  },
  "&:hover": {
    background: theme.palette.grey[300],
  },
  "&.active": {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& span": {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
  },
}));

export default function SignerSelect({ initialState, onChange, options }) {
  const [selectedSigners, setSelectedSigners] = React.useState(initialState);

  React.useEffect(() => {
    onChange(selectedSigners);
  }, [selectedSigners, onChange]);

  return (
    <Box mb={2} display="flex" flexWrap="wrap" gridGap="16px">
      {Object.values(options).map((option) => (
        <StyledButton
          className={selectedSigners[option.influencerId] ? "active" : ""}
          onClick={() => {
            setSelectedSigners((signers) => {
              if (signers[option.influencerId]) {
                return D.deleteKey(signers, option.influencerId);
              }
              return D.merge(signers, { [option.influencerId]: true });
            });
          }}
          key={option.influencerId}
        >
          <TinyAvatar alt={option.name} src={option.avatarUrl} />
          <span>{option.name}</span>
        </StyledButton>
      ))}
    </Box>
  );
}

SignerSelect.propTypes = {
  initialState: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
};

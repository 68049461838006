export function handleTwoFactorAuth(publicToken) {
  const source = new EventSource(`/api/stream?esid=${publicToken}`);
  return new Promise((resolve, reject) => {
    const onSuccess = (evt) => {
      source?.close?.();
      return resolve(JSON.parse(evt.data));
    };

    const onError = (evt) => {
      console.error(evt);
      source?.close?.();
      return reject(evt.data);
    };

    source.addEventListener("init", console.log);
    source.addEventListener("login.complete", onSuccess);
    source.addEventListener("error", onError);
  });
}

export async function handleRefreshToken({ ut }) {
  const response = await window.fetch(`/api/auth/refresh?ut=${ut}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }

  const json = await response.json();

  if (!json?.data) {
    throw new Error("Failed to refresh token");
  }

  return json.data;
}

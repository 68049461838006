import { Box, Typography } from "@material-ui/core";
import { A, D, G } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { useCurrentUser } from "../../../globalState";
import { useActiveInfluencer } from "../../../hooks";
import { Form, InfoTooltip } from "../../../theme";
import { convert, length } from "../../../util/convert";
import * as styles from "../styles";
import ProductImageInput from "./ProductImageInput";

const lengthEntries = [
  ...Object.entries(length.metric),
  ...Object.entries(length.imperial),
].filter(([unitValue]) => ["cm", "in", "mm", "ft"].includes(unitValue));

const inches = "in";

function round2(n) {
  return Math.round(n * 100) / 100;
}

function onBlurTrim({ key, setProduct }) {
  return (evt) => {
    const { target } = evt;
    const newValue = evt.target.value.trim();
    setProduct(D.set(key, newValue));
    target.value = newValue;
  };
}

function ProductDetailsStep({ stateAtom, productAtom, productSignersAtom }) {
  const currentUser = useCurrentUser();
  const activeInfluencer = useActiveInfluencer();
  const [signers, setSigners] = useAtom(productSignersAtom);

  const currentSigner = React.useMemo(
    () =>
      signers.find(
        (signer) => signer.influencerId === activeInfluencer.influencerId,
      ),
    [signers, activeInfluencer],
  );

  const [position, setPosition] = React.useState(currentSigner?.position ?? "");
  const [product, setProduct] = useAtom(productAtom);

  const [height, setHeight] = React.useState(product.heightInches ?? "");
  const [width, setWidth] = React.useState(product.widthInches ?? "");
  const [depth, setDepth] = React.useState(product.depthInches ?? "");
  const [unit, setUnit] = React.useState(product.dimensionsUnit ?? inches);

  const canEdit =
    currentUser.isAdmin || currentUser.isSuperadmin || signers.length < 2;

  React.useEffect(() => {
    setPosition(currentSigner?.position ?? "");
  }, [currentSigner]);

  const handleBlur = (key) => {
    return (evt) => {
      const { target } = evt;
      const newValue = Number(target.value);

      if (Number.isNaN(newValue) || newValue <= 0) {
        setProduct(D.set(key, null));
      } else {
        const converted = round2(convert(newValue).from(unit).to(inches));
        setProduct(D.set(key, converted));
      }
    };
  };

  const handleChangeUnit = (evt) => {
    const oldUnit = unit;
    const newUnit = evt.target.value;

    setUnit(newUnit);

    const update = (unit) =>
      unit ? round2(convert(unit).from(oldUnit).to(newUnit)) : "";

    setHeight(update);
    setWidth(update);
    setDepth(update);
  };

  React.useEffect(() => {
    setPosition(currentSigner?.position ?? "");
  }, [currentSigner]);

  return (
    <Box display="flex" flexDirection="column">
      <ProductImageInput
        disabled={!canEdit}
        productAtom={productAtom}
        stateAtom={stateAtom}
      />

      <Box mt={2} display="flex" flexDirection="column" gridGap="8px">
        <styles.Label>Name</styles.Label>

        <div>
          <Form.Input
            disabled={!canEdit}
            fullWidth
            size="small"
            value={product.name}
            placeholder="Product Name"
            inputProps={{ maxLength: 100 }}
            onChange={(evt) => setProduct(D.set("name", evt.target.value))}
            onBlur={onBlurTrim({ key: "name", setProduct })}
          />

          <styles.InputLengthStatus value={product.name} length={100} />
        </div>
      </Box>

      <Box display="flex" flexDirection="column" gridGap="8px">
        <styles.Label>Description</styles.Label>

        <div>
          <Form.Input
            disabled={!canEdit}
            fullWidth
            value={G.isString(product.subtext) ? product.subtext : ""}
            size="small"
            multiline
            rows={3}
            placeholder="Add a description of what you're selling"
            onChange={(evt) => setProduct(D.set("subtext", evt.target.value))}
            onBlur={onBlurTrim({ key: "subtext", setProduct })}
            inputProps={{ maxLength: 250 }}
          />

          <styles.InputLengthStatus value={product.subtext} length={250} />
        </div>
      </Box>

      {product.hasDimensions && (
        <div>
          <styles.StyledDimensions>
            <Box display="flex" gridGap="40px">
              <Box display="flex" flexDirection="column" gridGap="8px">
                <styles.Label>
                  Dimensions
                  {product.heightInches && product.widthInches && (
                    <Typography variant="body2" color="textSecondary">
                      {" "}
                      ({product.widthInches}&quot; x {product.heightInches}
                      &quot;
                      {product.depthInches && ` x ${product.depthInches}"`})
                    </Typography>
                  )}
                </styles.Label>

                <Box
                  mt={0.5}
                  display="flex"
                  gridGap="16px"
                  width="100%"
                  alignItems="center"
                >
                  <Form.Input
                    disabled={!canEdit}
                    inputProps={{ maxLength: 4, size: 4 }}
                    onBlur={handleBlur("widthInches")}
                    onChange={(evt) => {
                      setWidth(evt.target.value);
                    }}
                    label="Width"
                    required
                    size="small"
                    value={width}
                  />

                  <Form.Input
                    disabled={!canEdit}
                    inputProps={{ maxLength: 4, size: 4 }}
                    onBlur={handleBlur("heightInches")}
                    onChange={(evt) => {
                      setHeight(evt.target.value);
                    }}
                    label="Height"
                    required
                    size="small"
                    value={height}
                  />

                  <Form.Input
                    disabled={!canEdit}
                    inputProps={{ maxLength: 4, size: 4 }}
                    onBlur={handleBlur("depthInches")}
                    onChange={(evt) => {
                      setDepth(evt.target.value);
                    }}
                    label="Depth"
                    size="small"
                    value={depth}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gridGap="8px">
                <styles.Label>Units</styles.Label>

                <Box mt={0.5}>
                  <Form.Select
                    value={unit}
                    options={lengthEntries}
                    getOptionLabel={([, unit]) => unit.name.plural}
                    getOptionValue={([unitValue]) => unitValue}
                    onChange={handleChangeUnit}
                  />
                </Box>
              </Box>
            </Box>

            <Box mt={1} maxWidth="52ch">
              <Typography variant="caption" color="textSecondary">
                Depth is optional, but height and width are required. If the
                product is a print, enter the dimensions of the print and leave
                the depth blank.
              </Typography>
            </Box>
          </styles.StyledDimensions>
        </div>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        gridGap="16px"
        width="100%"
      >
        <Box display="flex" flexDirection="column" gridGap="8px">
          <styles.Label>Quantity</styles.Label>

          <Box mb={1} display="flex" alignItems="center" gridGap="16px">
            <Form.Input
              value={product.stock ?? ""}
              disabled={!canEdit || product.stock === null}
              size="small"
              placeholder={product.stock === null ? "N/A" : "Quantity"}
              onChange={(evt) =>
                setProduct(D.set("stock", Number(evt.target.value)))
              }
              inputProps={{
                type: "number",
                min: 0,
                max: 9999,
                size: 3,
                maxLength: 4,
                step: 1,
              }}
              error={product.stock < 0}
            />

            <Form.SmallCheckbox
              disabled={!canEdit}
              checked={product.stock === null}
              onChange={() =>
                setProduct(D.set("stock", product.stock === null ? 0 : null))
              }
              label="Unlimited"
            />
          </Box>
        </Box>
      </Box>

      {!activeInfluencer.isGroupShop && (
        <Box display="flex" flexDirection="column" gridGap="8px" my={1}>
          <div>
            <styles.Label>
              <span>Shop Position</span>
              <InfoTooltip
                size="small"
                content="Lower number to move to the top of the shop, higher to move to the bottom."
              />
            </styles.Label>
          </div>

          <div>
            <Form.Input
              type="text"
              size="small"
              variant="outlined"
              disabled={!currentSigner}
              value={position}
              inputProps={{
                type: "number",
                min: 1,
                size: 3,
                maxLength: 4,
                step: 1,
              }}
              onChange={(evt) => {
                setPosition(evt.target.value);
              }}
              onBlur={(evt) => {
                if (!currentSigner) {
                  return;
                }

                const trimmed = evt.target.value.trim();

                if (!trimmed) {
                  return;
                }

                const newNumber = Number.parseInt(trimmed, 10);

                if (Number.isNaN(newNumber)) {
                  return;
                }

                const fn = A.map((signer) =>
                  signer.influencerId === currentSigner.influencerId
                    ? D.merge(signer, { position: newNumber })
                    : signer,
                );

                setSigners(fn);
              }}
            />
          </div>
        </Box>
      )}
    </Box>
  );
}

ProductDetailsStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: ProductDetailsStep,
  title: "Product Details",
};

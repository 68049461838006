import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

export function useAgents(influencerId) {
  const { data, error, mutate } = useSWR(
    !influencerId ? null : `/api/influencerAgents/${influencerId}`,
    fetcher,
  );

  const agents = React.useMemo(() => {
    if (!Array.isArray(data?.agents)) {
      return [];
    }

    return data.agents;
  }, [data]);

  return {
    agents,
    error,
    isError: Boolean(error),
    isLoading: !error && !data,
    refresh: mutate,
  };
}

export function useAgentsIds(influencerIds) {
  const params = React.useMemo(() => {
    if (!influencerIds || !influencerIds?.length) {
      return null;
    }

    const result = new URLSearchParams();

    if (Array.isArray(influencerIds)) {
      result.set("ids", influencerIds.join(","));
    } else if (influencerIds) {
      result.set("ids", influencerIds);
    }

    return result;
  }, [influencerIds]);

  const { data, error, mutate } = useSWR(
    !params ? null : `/api/influencerAgents?${params}`,
    fetcher,
  );

  const agents = React.useMemo(() => {
    if (!Array.isArray(data?.agents)) {
      return [];
    }

    return data.agents ?? [];
  }, [data]);

  return {
    agents,
    error,
    isError: Boolean(error),
    isLoading: !error && !data,
    refresh: mutate,
  };
}

import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import * as constants from "../../components/Home/constants";
import ShopsSlider from "../../components/Home/Section/ShopsSlider";
import ProductSoldOutModal from "../../components/ProductSoldOutModal";
import {
  GroupShopInfluencers,
  ShopHeader,
  ShopHeaderWithNoProduct,
  ShopProducts,
} from "../../components/Shop";
import GroupShopArtists from "../../components/Shop/GroupShopArtists";
import ProductModal from "../../components/Shop/Products/ProductModal";
import { Influencer } from "../../domain";
import {
  useAutoScroll,
  useCurrentUser,
  useInfluencerRoute,
  useJsonLd,
  useMatchesSmDown,
  useRecomendedShops,
  useTracking,
} from "../../hooks";
import { useYouMightLike } from "../../hooks/useInfluencer";
import track from "../../services/UserTrackingActions";
import { PageContainer, StreamilyTabs } from "../../theme";
import { getParameter } from "../../util";

const displayOptionsFor = (influencer) =>
  ["Products", "Talent", influencer.showArtists ? "Artists" : null].filter(
    Boolean,
  );

const useStyles = makeStyles((theme) => ({
  recommendedShopSlider: {
    marginRight: "32px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },
}));

// eslint-disable-next-line complexity
export default function Shop() {
  const classes = useStyles();
  const { setTrackingCode } = useTracking();
  const history = useHistory();
  const { productId, route } = useParams();
  const currentUser = useCurrentUser();
  const { enableAutoScroll, disableAutoScroll } = useAutoScroll();
  const { influencer, isLoading, isError } = useInfluencerRoute();
  const { influencers: recommendedShops } = useRecomendedShops({ route });
  const { influencers: youMightLike } = useYouMightLike({ route });

  const [product, setProduct] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const isMobile = useMatchesSmDown();
  const boxEl = React.useRef(null);
  const [boxWidth, setBoxWidth] = React.useState(0);

  const updateWidth = React.useCallback(() => {
    if (!boxEl.current) {
      return;
    }

    setBoxWidth(() => {
      const { width } = boxEl.current.getBoundingClientRect();
      return width;
    });
  }, []);

  React.useEffect(() => {
    if (!influencer?.isGroupShop && currentTab !== 0) {
      setCurrentTab(0);
    }
  }, [currentTab, influencer]);

  React.useEffect(() => {
    disableAutoScroll();

    return () => {
      enableAutoScroll();
    };
  }, [disableAutoScroll, enableAutoScroll]);

  React.useEffect(() => {
    setTrackingCode();
  }, [setTrackingCode]);

  React.useEffect(() => {
    if (isError) {
      history.push("/");
    }
  }, [history, isError]);

  React.useEffect(() => {
    if (!influencer) {
      return;
    }

    track({
      userId: currentUser.userId,
      actionType: "shop-view",
      actionMetaData: {
        shopInfluencerId: influencer.influencerId,
        shopRoute: influencer.route,
        shopName: influencer.name,
        trackingCode:
          getParameter("stc") || localStorage?.getItem?.("stc") || null,
      },
    });

    if (!influencer.isOpen) {
      window.location.reload();
    }
  }, [currentUser, influencer]);

  React.useEffect(() => {
    if (product) return;
    if (!productId) return;
    if (!influencer?.products) return;

    const parsedParam = Number.parseInt(productId, 10);
    const shopProductId = Number.isInteger(parsedParam) ? parsedParam : null;

    const foundProduct = influencer.products.find(
      (product) =>
        product.productId === shopProductId &&
        !product.isSoldOut &&
        !product.vaultedUntil,
    );

    if (foundProduct) {
      setProduct(foundProduct);
    }
  }, [influencer, productId, product]);

  const closeProductDialog = React.useCallback((fn) => {
    setProduct(null);
    if (typeof fn === "function") fn();
  }, []);

  if (!influencer?.isOpen || isLoading || isError) {
    return null;
  }

  const influencerDescription = [influencer.name, influencer.caption]
    .filter(Boolean)
    .join(": ");

  return (
    <PageContainer>
      <Helmet>
        <title>Streamily | {influencer.name}</title>

        <meta name="description" content={influencerDescription} />

        {Boolean(influencer.metaTag) && (
          <meta name="keywords" content={influencer.metaTag.famousFor} />
        )}
      </Helmet>

      {influencer?.products?.length && <ShopHeader />}

      {!influencer?.products?.length && <ShopHeaderWithNoProduct />}

      {influencer?.isGroupShop && (
        <Box width="100%" display="flex" justifyContent="center">
          <StreamilyTabs
            options={displayOptionsFor(influencer)}
            currentTab={currentTab}
            onChange={setCurrentTab}
            size="lg"
          />
        </Box>
      )}

      {currentTab === 0 && <ShopProducts setProduct={setProduct} />}

      {currentTab === 0 && (
        <>
          {!influencer.products && youMightLike?.length > 0 && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h5">You might like...</Typography>
              </Grid>
              <Grid className={classes.recommendedShopSlider} item>
                <ShopsSlider
                  influencers={youMightLike}
                  isMobile={isMobile}
                  isDark={false}
                  numberPerRow={6}
                  updateWidth={updateWidth}
                  look={constants.LOOK_WIDE}
                  count={true}
                />
              </Grid>
            </Grid>
          )}

          {!influencer.products && recommendedShops?.length > 0 && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h5">Recommended Shops</Typography>
              </Grid>
              <Grid className={classes.recommendedShopSlider} item>
                <ShopsSlider
                  influencers={recommendedShops}
                  isMobile={isMobile}
                  isDark={false}
                  numberPerRow={6}
                  updateWidth={updateWidth}
                  count={true}
                  recommendedShops={true}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {currentTab === 1 && influencer?.isGroupShop && (
        <GroupShopInfluencers influencer={influencer} />
      )}

      {currentTab === 2 &&
        influencer?.isGroupShop &&
        influencer?.showArtists && <GroupShopArtists influencer={influencer} />}

      {influencer && <MetaData influencer={influencer} />}

      {Boolean(product) && !product.isSoldOut && !product.vaultedUntil && (
        <ProductModal
          product={product}
          open={Boolean(product)}
          onClose={closeProductDialog}
          influencer={influencer}
        />
      )}

      {Boolean(product) && (product.isSoldOut || product.vaultedUntil) && (
        <ProductSoldOutModal
          product={product}
          open={Boolean(product)}
          onClose={closeProductDialog}
          influencer={influencer}
        />
      )}
    </PageContainer>
  );
}

function MetaData({ influencer }) {
  useJsonLd({
    "@context": "http://schema.org/",
    "@type": "Person",
    name: influencer.name,
  });

  return null;
}

MetaData.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};

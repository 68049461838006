import { Box, Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useUsers } from "../../hooks";
import { Form } from "../../theme";

const params = { is_agent: true };

const nameFor = (user) => {
  const parts = [user.firstName, user.lastName].filter(Boolean);

  if (parts.length === 0) {
    return "No Name";
  }

  return parts.join(" ");
};

const AgentSearch = React.memo(function AgentSearch({
  isError,
  isLoading,
  addAgent,
  setUser,
  user,
  errorMessage,
  setErrorMessage,
}) {
  const [amount, setAmount] = React.useState("");
  const inputRef = React.useRef(null);
  const { users } = useUsers(params);
  /*
   * onClick handles adding a new Salesperson to the user's list of
   * workingSalespeople. This fails if the user is already at their max amount of
   * salespeople.
   */
  const onClick = async () => {
    const cents = Number(amount) * 100;

    if (Number.isNaN(cents) || cents < 0 || cents > 100_000) {
      setErrorMessage(
        "Invalid amount (must be greater than or equal to $0 and less than or equal to $1,000)",
      );
      return;
    }

    const newAgent = { userId: user.userId, splitAmount: cents };

    const isSuccess = await addAgent(newAgent);

    if (isSuccess) {
      setUser(null);
      setAmount("");
    }
  };

  const onChange = (_, newValue) => {
    if (newValue) {
      setUser(newValue);
    } else {
      setUser(null);
    }

    window.requestAnimationFrame(() => {
      inputRef.current?.blur?.();
    });
  };

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputRef={inputRef}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label="Agent Email"
      size="small"
    />
  );

  const shouldDisable = user === null || isLoading || isError;

  return (
    <Box>
      <Box mb={1}>
        <Typography gutterBottom variant="h6">
          Add Agent
        </Typography>
      </Box>

      <Box mb={1} display="grid" gridGap="8px" gridTemplateColumns="1fr 96px">
        <Form.Autocomplete
          fullWidth
          autoComplete
          clearOnBlur={!user?.email}
          freeSolo
          handleHomeEndKeys
          includeInputInList
          options={users}
          value={user?.email ?? ""} // This is required to clear the input
          selectOnFocus
          size="small"
          onChange={onChange}
          getOptionLabel={(user) => {
            if (G.isString(user)) {
              return user;
            }
            return user.email;
          }}
          renderInput={renderInput}
          renderOption={(user) => (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2">{nameFor(user)}</Typography>

              <Typography variant="caption" color="textSecondary">
                {user.email}
              </Typography>
            </Box>
          )}
          onClick={onClick}
        />

        <Form.Input
          inputProps={{ size: 8, inputMode: "numeric" }}
          InputProps={{ startAdornment: "$" }}
          shrink
          label="Amount"
          size="small"
          variant="outlined"
          value={amount}
          onChange={(evt) => {
            setAmount(evt.target.value);
          }}
        />
      </Box>

      <Box
        alignItems="center"
        display="flex"
        gridGap="16px"
        justifyContent="flex-end"
        mb={2}
      >
        {errorMessage && (
          <Typography
            color="error"
            variant="body2"
            onClick={() => {
              setErrorMessage("");
            }}
          >
            {errorMessage}
          </Typography>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={onClick}
          startIcon={<AddIcon />}
          disabled={shouldDisable}
          style={{ minWidth: 150 }}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
});

AgentSearch.propTypes = {
  customParams: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  agents: PropTypes.arrayOf(
    PropTypes.shape({ userId: PropTypes.number, percentage: PropTypes.number }),
  ).isRequired,
  addAgent: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  user: PropTypes.shape({
    created: PropTypes.instanceOf(Date),
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.number,
  }),
};

export default AgentSearch;

import { Box, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { Form } from "../../../theme";
import * as styles from "../styles";
import { format, MAX_PRODUCT_PRICE } from "../utils";
import AgentSplit from "./AgentSplit";

export default function OtherSigner({
  agents,
  currentUser,
  handleAddAgent,
  handleRemoveAgent,
  handleRemoveSigner,
  handleUpdateAmount,
  handleUpdateNoFulfillment,
  signer,
}) {
  return (
    <Box display="flex" flexDirection="column" gridGap="4px">
      <styles.StyledSignerBox>
        <Box>
          <styles.Label>How much does {signer.name} want to make</styles.Label>

          {currentUser.isAdmin && (
            <Form.SmallCheckbox
              checked={signer.noFulfillment}
              onChange={handleUpdateNoFulfillment(signer)}
              style={{ marginLeft: 8, marginRight: 8, padding: 0 }}
              label="No Signature Required"
              size="small"
              title="This talent will not be required to sign the product in order to mark it as complete."
            />
          )}
        </Box>

        <Form.Input
          className="grey-filled"
          type="number"
          inputProps={{
            size: 2,
            max: MAX_PRODUCT_PRICE,
            min: 1,
            step: 1,
          }}
          InputProps={{ startAdornment: "$" }}
          defaultValue={format(signer?.splitAmount ?? 0)}
          size="small"
          placeholder="60"
          onChange={handleUpdateAmount(signer)}
          onBlur={handleUpdateAmount(signer, true)}
          disabled={!currentUser.isAdmin}
        />

        {currentUser.isAdmin && (
          <Box minWidth={42} justifySelf="flex-end">
            <IconButton onClick={() => handleRemoveSigner(signer)}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
      </styles.StyledSignerBox>

      {agents?.map((agent) => (
        <AgentSplit
          agent={agent}
          currentUser={currentUser}
          handleAddAgent={handleAddAgent}
          handleRemoveAgent={handleRemoveAgent}
          key={agent.userId}
          signer={signer}
        />
      ))}
    </Box>
  );
}

OtherSigner.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  handleAddAgent: PropTypes.func,
  handleRemoveAgent: PropTypes.func,
  handleRemoveSigner: PropTypes.func,
  handleUpdateAmount: PropTypes.func,
  handleUpdateNoFulfillment: PropTypes.func,
  signer: PropTypes.object,
};

import { Box, Switch, Typography } from "@material-ui/core";
import { B, D, G } from "@mobily/ts-belt";
import clsx from "clsx";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../../domain";
import { useCurrentUser } from "../../../globalState";
import { useActiveInfluencer, useMatchesXsDown } from "../../../hooks";
import { Form } from "../../../theme";
import { featureFlags } from "../../../util";
import * as styles from "../styles";
import {
  createOrUpdateProduct,
  createWorkingProduct,
  DEFAULT_BUMP_PERCENTAGE,
  STEPS,
} from "../utils";
import SignTypeChangeConfirm from "./SignTypeChangeConfirm";
import SignTypeDescribeModal from "./SignTypeDescribeModal";

function isVideoShoutoutFor({ product, defaultShoutout }) {
  return (
    product.signedType === SignType.shoutout &&
    product.productTypeId === defaultShoutout.productTypeId
  );
}

function isAudioShoutoutFor({ product, defaultAudioShoutout }) {
  return (
    product.signedType === SignType.audioShoutout &&
    product.productTypeId === defaultAudioShoutout.productTypeId
  );
}

function getOptionLabel(signedType) {
  const type = SignType.type(signedType);

  return (
    <Box>
      <styles.Label>
        {type?.label || G.isString(signedType) ? signedType : "Unknown"}
      </styles.Label>

      <Typography variant="caption" color="textSecondary">
        {type?.descrShort || "Unknown"}
      </Typography>
    </Box>
  );
}

function CreateProductStep({
  defaultProductType,
  defaultShoutout,
  defaultAudioShoutout,
  onClose,
  productAtom,
  productTypes,
  productTagsAtom,
  productSignersAtom,
  signedTypes,
  stateAtom,
}) {
  const matchesXsDown = useMatchesXsDown();
  const activeInfluencer = useActiveInfluencer();
  const currentUser = useCurrentUser();
  const [product, setProduct] = useAtom(productAtom);
  const [productTags, setProductTags] = useAtom(productTagsAtom);
  const [signers, setSigners] = useAtom(productSignersAtom);
  const [state, setState] = useAtom(stateAtom);

  const [showDescribeModal, setShowDescribeModal] = React.useState(false);
  const [newSignedType, setNewSignType] = React.useState(null);

  const workingProductTypes = React.useMemo(() => {
    const name = product?.productType;
    const productTypeId = product?.productTypeId;

    if (
      productTypeId === null ||
      productTypes.find((pt) => pt.productTypeId === productTypeId)
    ) {
      return productTypes.concat({ name: "Other", productTypeId: "Other" });
    }

    return productTypes.concat({ name, productTypeId });
  }, [product.productType, product.productTypeId, productTypes]);

  const setAutograph = () => {
    setProduct(
      D.merge({
        bumpPercentage:
          defaultProductType.bumpPercentage ?? DEFAULT_BUMP_PERCENTAGE,
        productTypeAspectRatio: defaultProductType.aspectRatio,
        productType: defaultProductType.name,
        productTypeId: defaultProductType.productTypeId,
        hasDimensions: Boolean(defaultProductType.hasDimensions),
        signedType: SignType.livestream,
      }),
    );
  };

  const setVideoShoutout = () => {
    setProduct(
      D.merge({
        bumpPercentage:
          defaultShoutout.bumpPercentage ?? DEFAULT_BUMP_PERCENTAGE,
        productTypeAspectRatio: 1,
        productType: defaultShoutout.name,
        productTypeId: defaultShoutout.productTypeId,
        signedType: SignType.shoutout,
        hasDimensions: false,
      }),
    );
  };

  const setAudioShoutout = () => {
    setProduct(
      D.merge({
        bumpPercentage:
          defaultShoutout.bumpPercentage ?? DEFAULT_BUMP_PERCENTAGE,
        productTypeAspectRatio: 1,
        productType: defaultAudioShoutout.name,
        productTypeId: defaultAudioShoutout.productTypeId,
        signedType: SignType.audioShoutout,
      }),
    );
  };

  const onChangeProductType = (evt) => {
    if (evt.target.value === "Other") {
      return setProduct(
        D.merge({
          bumpPercentage: DEFAULT_BUMP_PERCENTAGE,
          productTypeId: null,
          productType: "Other",
          hasDimensions: false,
        }),
      );
    }

    const productTypeId = Number(evt.target.value);
    const productType = productTypes.find(
      (pt) => pt.productTypeId === productTypeId,
    );

    if (!productType) {
      return;
    }

    setProduct(
      D.merge({
        bumpPercentage: productType.bumpPercentage ?? DEFAULT_BUMP_PERCENTAGE,
        productTypeAspectRatio: productType.aspectRatio,
        productTypeId: productType.productTypeId,
        productType: productType.name,
        hasDimensions: Boolean(productType.hasDimensions),
      }),
    );
  };

  const onDuplicateProduct = async ({ signedType, deactivateParent }) => {
    try {
      const newProduct = await createOrUpdateProduct({
        product: D.merge(product, { signedType }),
        productTags,
        signers,
        state: D.merge(state, { deactivateParent, duplicate: true }),
      });

      setProduct(
        createWorkingProduct({
          product: newProduct,
          activeInfluencer,
          defaultProductType,
        }),
      );

      setProductTags(newProduct.tags);
      setSigners(newProduct.signers);

      setState(
        D.merge({
          isEditing: true,
          hasError: false,
          currentStep: STEPS.PRODUCT_SUMMARY,
        }),
      );

      onClose?.({ shouldRefreshInfluencer: true });
    } catch (err) {
      setState(D.merge({ hasError: true, errorMessage: err.message }));
    }
  };

  const isEditing = Boolean(product.productId);
  const isVideoShoutout = isVideoShoutoutFor({ product, defaultShoutout });
  const isAudioShoutout = isAudioShoutoutFor({ product, defaultAudioShoutout });
  const isShoutout = isVideoShoutout || isAudioShoutout;

  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      <styles.Label>What do you want to sell?</styles.Label>

      <Box
        alignItems="center"
        display="flex"
        flexDirection={matchesXsDown ? "column" : "row"}
        gridGap="16px"
        justifyContent="center"
        mb={1}
      >
        <styles.SelectButton
          disabled={!isShoutout}
          className={clsx(!isShoutout && "selected")}
          onClick={setAutograph}
        >
          Autograph
        </styles.SelectButton>

        <styles.SelectButton
          disabled={isEditing || isVideoShoutout}
          className={clsx(isVideoShoutout && "selected")}
          onClick={setVideoShoutout}
        >
          Video Shoutout
          <br />
          (No Autograph)
        </styles.SelectButton>

        <styles.SelectButton
          disabled={isEditing || isAudioShoutout}
          className={clsx(isAudioShoutout && "selected")}
          onClick={setAudioShoutout}
        >
          Audio Shoutout
          <br />
          (No Autograph)
        </styles.SelectButton>
      </Box>

      {!isShoutout && (
        <>
          <styles.Label>What will you autograph?</styles.Label>

          <Box mb={1}>
            <Form.FancySelect
              formControlProps={{ fullWidth: true }}
              size="small"
              variant="outlined"
              value={product.productTypeId ?? "Other"}
              options={workingProductTypes}
              getOptionLabel={D.get("name")}
              getOptionValue={D.get("productTypeId")}
              onChange={onChangeProductType}
              disabled={
                (isEditing || isShoutout) &&
                !(currentUser.isAdmin || currentUser.isSuperadmin)
              }
            />
          </Box>

          <styles.Label>How will you sign it?</styles.Label>

          <Form.FancySelect
            formControlProps={{ fullWidth: true }}
            size="small"
            variant="outlined"
            value={product.signedType ?? SignType.livestream}
            options={signedTypes}
            onChange={(evt) => {
              if (state.isEditing) {
                setNewSignType(evt.target.value);
              } else {
                setProduct(D.set("signedType", evt.target.value));
              }
            }}
            renderValue={SignType.toLabel}
            getOptionLabel={getOptionLabel}
            disabled={isShoutout}
          />

          <styles.TextButton
            className="plain"
            onClick={() => setShowDescribeModal(true)}
          >
            What are Sign Types?
          </styles.TextButton>

          {featureFlags.SIGN_CLARITY && showDescribeModal && (
            <SignTypeDescribeModal
              open={showDescribeModal}
              onClose={() => setShowDescribeModal(false)}
            />
          )}
        </>
      )}

      {currentUser.isAdmin && (
        <Box
          alignItems="center"
          component="label"
          display="flex"
          gridGap="16px"
          htmlFor="product-modal-convention-switch"
          justifyContent="space-between"
          mt={1}
          width="100%"
        >
          <Box>
            <styles.Label htmlFor="product-modal-convention-switch">
              Convention
            </styles.Label>
            <Typography variant="caption" color="textSecondary">
              Mark as convention product.
            </Typography>
          </Box>

          <Switch
            id="product-modal-convention-switch"
            color="secondary"
            checked={Boolean(product.convention)}
            onChange={() => setProduct(D.update("convention", B.not))}
          />
        </Box>
      )}

      {Boolean(newSignedType) && (
        <SignTypeChangeConfirm
          product={product}
          open={Boolean(newSignedType)}
          newSignType={newSignedType}
          onClose={() => {
            setNewSignType(null);
          }}
          onSave={(signedType, deactivateParent) => {
            setNewSignType(null);
            onDuplicateProduct({ signedType, deactivateParent });
          }}
        />
      )}
    </Box>
  );
}

CreateProductStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      productTypeId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      bumpPercentage: PropTypes.number,
      hasDimensions: PropTypes.bool,
    }),
  ).isRequired,
  defaultProductType: PropTypes.shape({
    productTypeId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    bumpPercentage: PropTypes.number,
    aspectRatio: PropTypes.number,
    hasDimensions: PropTypes.bool,
  }),
  defaultShoutout: PropTypes.shape({
    productTypeId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    bumpPercentage: PropTypes.number,
    hasDimensions: PropTypes.bool,
  }),
  defaultAudioShoutout: PropTypes.shape({
    productTypeId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    bumpPercentage: PropTypes.number,
  }),
  productTagsAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  signedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  stateAtom: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default {
  Component: CreateProductStep,
  title: "Create Product",
};

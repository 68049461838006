import { D } from "@mobily/ts-belt";
import { useAtomValue } from "jotai";
import * as React from "react";
import { SignType } from "../../domain";

export const DEFAULT_BUMP_PERCENTAGE = 0.22;
export const MAX_PRODUCT_PRICE = 1000000; // $10000 in cents
export const MIN_PRODUCT_PRICE = 0; // $0 in cents
export const AGENT_TYPES = {
  AGENT: "agent",
  AGENT_MANUAL: "agentManual",
};

export const STEPS = Object.freeze(
  Object.seal({
    CREATE_PRODUCT: 0,
    PRODUCT_DETAILS: 1,
    SPLITS: 2,
    INCREASE_EXPOSURE: 3,
    PRODUCT_SUMMARY: 4,
  }),
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const format = (n) =>
  currencyFormatter
    .format(n / 100)
    .replace("$", "")
    .replace(",", "");

export function formatAgentToFee({
  influencerId,
  userId,
  firstName,
  email,
  splitAmount,
}) {
  return {
    influencerId,
    userId,
    type: "agent",
    title: `Agent Fee for ${firstName ?? email}`,
    description:
      "Amount that will be paid out to the agent associated with the above shop. Agent split amounts cannot be changed, only removed on a per-product basis.",
    amount: splitAmount,
  };
}

export function createWorkingSigners({ product, activeInfluencer }) {
  if (!product) {
    return [
      {
        active: true,
        avatarUrl: activeInfluencer.avatarUrl,
        influencerId: activeInfluencer.influencerId,
        name: activeInfluencer.name,
        noFulfillment: false,
        position: 1,
        route: activeInfluencer.route,
        splitAmount: 6000,
      },
    ];
  }

  return product.signers;
}

export function createInitialState(product) {
  return {
    currentStep: product?.productId
      ? STEPS.PRODUCT_SUMMARY
      : STEPS.CREATE_PRODUCT,
    didConfirmTypeWarning: false,
    didShowEducationalMessage: false,
    errorMessage: "",
    hasError: false,
    isEditing: Boolean(product?.productId),
    isLoading: false,
    isOpen: true,
    showTypeWarning: false,
  };
}

export function createWorkingProduct({
  product,
  defaultProductType,
  activeInfluencer,
}) {
  if (!product) {
    return {
      active: true,
      additionalBumpAmount: defaultProductType.additionalBumpAmount,
      allowNotes: true,
      allowPersonalization: true,
      blockCertOfAuth: false,
      bumpPercentage: defaultProductType.bumpPercentage,
      characterTagString: null,
      convention: false,
      disableVip: false,
      fixedBump: false,
      franchiseTagString: null,
      imageUrl: "",
      influencerId: activeInfluencer.influencerId,
      name: "",
      noShipping: false,
      originalImageUrl: "",
      productBump: 0,
      productId: null,
      productTypeAspectRatio: defaultProductType.aspectRatio,
      productType: defaultProductType.name,
      productTypeId: defaultProductType.productTypeId,
      signedType: SignType.livestream,
      stock: null,
      subtext: "",
      vaultedUntil: null,
      hasDimensions: false,
      heightInches: null,
      widthInches: null,
      depthInches: null,
    };
  }

  return {
    active: product.active,
    additionalBumpAmount: product.additionalBumpAmount,
    allowNotes: product.allowNotes,
    allowPersonalization: product.allowPersonalization,
    blockCertOfAuth: Boolean(product.blockCertOfAuth),
    bumpPercentage: product.bumpPercentage,
    characterTagString: product.characterTagString || null,
    convention: Boolean(product.convention),
    disableVip: Boolean(product.disableVip),
    fixedBump: Boolean(product.fixedBump),
    franchiseTagString: product.franchiseTagString || null,
    imageUrl: product.imageUrl,
    influencerId: product.influencerId,
    name: product.name,
    noShipping: Boolean(product.noShipping),
    originalImageUrl: product.originalImageUrl,
    productBump: product.productBump,
    productId: product.productId,
    productTypeAspectRatio: product.productTypeAspectRatio,
    productTypeId: product.productTypeId,
    productType: product.productType,
    signedType: product.signedType,
    stock: product.stock,
    subtext: product.subtext ?? "",
    vaultedUntil: product.vaultedUntil ?? null,
    hasDimensions: Boolean(product.hasDimensions),
    heightInches: product.heightInches ?? null,
    widthInches: product.widthInches ?? null,
    depthInches: product.depthInches ?? null,
  };
}

export function getImageFromDropEvent({ dataTransfer }) {
  let file;

  if (dataTransfer.items) {
    const foundItem = [...dataTransfer.items].find(
      (item) => item.kind === "file" && item.type.startsWith("image/"),
    );

    file = foundItem?.getAsFile?.();
  } else {
    file = [...dataTransfer.files].find((file) =>
      file.type.startsWith("image/"),
    );
  }

  return file;
}

export function getImageFromFileInputEvent({ target }) {
  const { files } = target;
  return [...files].find((file) => file.type.startsWith("image/"));
}

export function getDefaultProductTypes({ productTypes, activeInfluencer }) {
  if (!productTypes || !productTypes.length) {
    return {};
  }

  let defaultAudioShoutout;
  let defaultDomestic;
  let defaultInternational;
  let defaultProductType;
  let defaultShoutout;

  productTypes.forEach((productType) => {
    if (productType.isDefaultDomestic) {
      defaultDomestic = productType;
    } else if (productType.isDefaultInternational) {
      defaultInternational = productType;
    }

    if (activeInfluencer.isDomestic && productType.isDefaultDomestic) {
      defaultProductType = productType;
    } else if (
      !activeInfluencer.isDomestic &&
      productType.isDefaultInternational
    ) {
      defaultProductType = productType;
    } else if (productType.isDefaultShoutout) {
      defaultShoutout = productType;
    } else if (productType.isDefaultAudioShoutout) {
      defaultAudioShoutout = productType;
    }
  });

  return {
    defaultAudioShoutout,
    defaultDomestic,
    defaultInternational,
    defaultProductType,
    defaultShoutout,
  };
}

export function nextTextFor({ currentStep = 0, isEditing }) {
  if (currentStep === STEPS.PRODUCT_SUMMARY) {
    return isEditing ? "Save Product" : "Create Product";
  }

  if (isEditing || currentStep === STEPS.INCREASE_EXPOSURE) {
    return "Review Product";
  }

  return "Next";
}

export function agentsToArray(agents = {}) {
  if (D.isEmpty(agents)) {
    return [];
  }

  return Object.values(agents).flat();
}

export function agentsFromArray(agentsArray = []) {
  return agentsArray.reduce((acc, agent) => {
    const existingAgents = acc[agent.influencerId] ?? [];
    existingAgents.push(agent);
    acc[agent.influencerId] = existingAgents;
    return acc;
  }, {});
}

export async function createOrUpdateProduct({
  agents,
  characters,
  product,
  productTags,
  signers,
  state,
}) {
  const isEditingOrDuplicate = state.isEditing || state.duplicate;

  const method = isEditingOrDuplicate ? "PUT" : "POST";

  const endpoint = `/api/product/shopBuilder${
    isEditingOrDuplicate ? `/${product.productId}` : ""
  }`;

  const body = {
    duplicate: Boolean(state.duplicate),
    deactivateParent: Boolean(state.deactivateParent),
    product: D.merge(product, {
      agents: agentsToArray(agents),
      characters,
      signers,
      tags: productTags,
    }),
  };

  const response = await window.fetch(endpoint, {
    method, // POST or PUT
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  if (!response.ok || json.errors) {
    const { errors, message } = json;
    const errorMessage =
      message ?? errors?.[0]?.message ?? "Something went wrong";
    throw new Error(errorMessage);
  }

  return json.product;
}

export function usePreviousState(stateAtom) {
  const state = useAtomValue(stateAtom);
  const [prevState, setPrevState] = React.useState(null);

  React.useEffect(() => {
    setPrevState((prevState) => (prevState !== state ? state : prevState));
  }, [state]);

  return prevState;
}

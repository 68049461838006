import { Box, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { Form, InfoTooltip } from "../../../theme";
import * as styles from "../styles";
import { format, MAX_PRODUCT_PRICE } from "../utils";
import AgentSplit from "./AgentSplit";

export default function CurrentSigner({
  agents,
  currentSigner,
  currentUser,
  handleAddAgent,
  handleRemoveAgent,
  handleRemoveSigner,
  handleUpdateAmount,
  handleUpdateNoFulfillment,
  label,
}) {
  return (
    <Box display="flex" flexDirection="column" gridGap="4px">
      <styles.StyledSignerBox>
        <Box>
          <styles.Label>
            {label}
            <InfoTooltip
              title={label}
              content="Any affiliate cuts will be deducted from this amount"
              size="small"
            />
          </styles.Label>

          {currentUser.isAdmin && (
            <Form.SmallCheckbox
              style={{ marginLeft: 8, marginRight: 8, padding: 0 }}
              label="No Signature Required"
              size="small"
              checked={currentSigner.noFulfillment}
              onChange={handleUpdateNoFulfillment(currentSigner)}
              title="This talent will not be required to sign the product in order to mark it as complete."
            />
          )}
        </Box>

        <Form.Input
          className="grey-filled"
          type="number"
          inputProps={{ size: 2, max: MAX_PRODUCT_PRICE, min: 1, step: 1 }}
          InputProps={{ startAdornment: "$" }}
          required
          defaultValue={format(currentSigner?.splitAmount ?? 0)}
          size="small"
          placeholder="60"
          onChange={handleUpdateAmount(currentSigner)}
          onBlur={handleUpdateAmount(currentSigner, true)}
        />

        {currentUser.isAdmin && (
          <Box minWidth={42} justifySelf="flex-end">
            <IconButton onClick={() => handleRemoveSigner(currentSigner)}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
      </styles.StyledSignerBox>

      {agents?.map((agent) => (
        <AgentSplit
          agent={agent}
          currentUser={currentUser}
          handleAddAgent={handleAddAgent}
          handleRemoveAgent={handleRemoveAgent}
          key={agent.userId}
          signer={currentSigner}
        />
      ))}
    </Box>
  );
}

CurrentSigner.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSigner: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleAddAgent: PropTypes.func.isRequired,
  handleRemoveAgent: PropTypes.func.isRequired,
  handleRemoveSigner: PropTypes.func.isRequired,
  handleUpdateAmount: PropTypes.func.isRequired,
  handleUpdateNoFulfillment: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

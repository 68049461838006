import { Box } from "@material-ui/core";
import { B, D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../domain";
import SignTypeBadge from "../SignTypeBadge";

export default function SignedTypeSelect({ initialState, onChange }) {
  const [signedTypes, setSignedTypes] = React.useState(initialState);

  React.useEffect(() => {
    onChange(signedTypes);
  }, [signedTypes, onChange]);

  return (
    <Box display="flex" gridGap="8px" flexWrap="wrap">
      {SignType.list().map((signedType) => (
        <SignTypeBadge
          active={Boolean(signedTypes[signedType])}
          key={signedType}
          label={SignType.toLabel(signedType)}
          signedType={signedType}
          onClick={() => {
            setSignedTypes(D.update(signedType, B.not));
          }}
        />
      ))}
    </Box>
  );
}

SignedTypeSelect.propTypes = {
  initialState: PropTypes.object,
  onChange: PropTypes.func,
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function formatDate(date) {
  const timeData = new Date(date);
  let timeHours = timeData.getHours();
  let amOrPm = "AM";
  if (timeHours >= 12) {
    amOrPm = "PM";
    timeHours -= 12;
  }
  if (timeHours === 0) {
    timeHours = 12;
  }
  let timeMinutes = timeData.getMinutes();
  if (timeMinutes < 10) timeMinutes = `0${timeMinutes}`;
  const timeDate = `${
    timeData.getMonth() + 1
  }/${timeData.getDate()}/${timeData.getFullYear()}`;
  const timeTime = `${timeHours}:${timeMinutes} ${amOrPm}`;
  const monthStr = months[timeData.getMonth()];
  const dayStr = timeData.getDate();

  let timeTimezoneOffset = Number.parseInt(
    timeData.getTimezoneOffset() / -60,
    10,
  );

  if (timeTimezoneOffset >= 0) {
    timeTimezoneOffset = `+${timeTimezoneOffset}`;
  } else {
    timeTimezoneOffset = String(timeTimezoneOffset);
  }

  return {
    date: timeDate,
    time: timeTime,
    monthStr,
    monthNum: timeData.getMonth() + 1,
    dayStr,
    timezoneOffset: timeTimezoneOffset,
  };
}

// Get current UTC time
export function getCurrentTimezoneOffset() {
  const date = new Date();
  const offset = date.getTimezoneOffset() * 60 * 1000;

  return new Date(date.getTime() + offset);
}

export function matchesDateTime(str) {
  return /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/.test(
    str,
  );
}

export const dateFormatter = new Intl.DateTimeFormat("en-US");

export const dateTimeFormatterUS = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
  dateStyle: "short",
});

export const dateTimeFormatter = Object.seal(
  Object.freeze({
    format(input, formatter = dateTimeFormatterUS) {
      try {
        if (input instanceof Date) {
          return formatter.format(input);
        }

        return formatter.format(new Date(input));
      } catch {
        // For safari
        try {
          return formatter.format(new Date(input.replace(/-/g, "/")));
        } catch {
          // do nothing
        }
        return "No timestamp";
      }
    },
  }),
);

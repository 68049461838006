import * as React from "react";
import useSWR from "swr";
import { fetcher } from "../../hooks";

export const MAX_SALESPEOPLE = 1;

export function getOptionLabel(option) {
  return `${option.firstName} ${option.lastName} - ${option.email}`;
}

export function useSalespersons(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/influencer/${influencerId}/salespersons`,
    fetcher,
  );

  const salespersons = React.useMemo(() => {
    if (!data?.salespersons || !Array.isArray(data.salespersons)) {
      return [];
    }

    return data.salespersons;
  }, [data]);

  return {
    error,
    isError: Boolean(error),
    isLoading: !error && !data,
    mutate,
    salespersons,
  };
}

export async function updateSalespeople({ influencerId, salespersons }) {
  const response = await window.fetch(
    `/api/influencer/${influencerId}/salespersons`,
    {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ salespersons }),
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return salespersons;
}

import { styled } from "@material-ui/core/styles";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Money, Product, SignType } from "../../domain";
import { useLivestreamWarningContext } from "../../pages/Dashboard/ShopEditor";
import AffirmIcons from "../Checkout/Form/AffirmIcons";
import SignTypeBadge from "../SignTypeBadge";

function shouldShowAffirm(product) {
  return (
    product &&
    Money.add(product.price, product.productBump).gt(
      Money.for({ value: 500, currency: "usd" }),
    )
  );
}

const ProductImage = React.memo(function ProductImage({
  product,
  isStorefront = false,
  isDark,
}) {
  const livestreamWarning = useLivestreamWarningContext();
  const shouldShowWarning =
    livestreamWarning && product.signedType === SignType.livestream;

  const isVaulted = Boolean(product.vaultedUntil);

  return (
    <div
      className="product-image__container"
      title={
        !isStorefront && shouldShowWarning
          ? "This product will not be available to customers until an event date has been set"
          : ""
      }
    >
      {isVaulted && (
        <div className="product-image__vaulted-overlay">
          <span className="vaulted-text">VAULTED</span>
        </div>
      )}

      {product.isSoldOut && !isVaulted && (
        <div className="product-image__sold-out-overlay">
          <span className="sold-out-text">SOLD OUT</span>
        </div>
      )}

      {!isStorefront && shouldShowWarning && (
        <div className="top-left">
          <ErrorRoundedIcon style={{ color: "red", fontSize: 30 }} />
        </div>
      )}

      <div className="top-right">
        <SignTypeBadge
          signedType={product.signedType}
          label={SignType.toLabel(product.signedType)}
        />
      </div>

      <div
        className={clsx(
          "product-image__image-container",
          product.shouldContainImage && "contained",
          isDark && "dark",
          "fancy",
        )}
      >
        <img
          className="product-image__image"
          loading="lazy"
          src={product?.imageUrl}
          alt={product?.name}
        />
      </div>

      {isStorefront && shouldShowAffirm(product) && (
        <StyledBox>
          <AffirmIcons shadow size="small" />
        </StyledBox>
      )}
    </div>
  );
});

ProductImage.propTypes = {
  product: PropTypes.instanceOf(Product),
  isStorefront: PropTypes.bool,
  isDark: PropTypes.bool,
};

const StyledBox = styled("div")(() => ({
  position: "absolute",
  bottom: 8,
  left: 8,
  height: 28,
  display: "flex",
  alignItems: "center",
  padding: "0 4px",
  borderRadius: "10px 20px 20px 10px",
  background: "rgba(0, 0, 0, 0.2)",
}));

export default ProductImage;

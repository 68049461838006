import { D } from "@mobily/ts-belt";
import { agentsToArray } from "../utils";
import { SignType } from "../../../domain";

const signingTypeBumpMap = {
  [SignType.livestream]: 500,
  [SignType.offCamera]: 300,
};

export function totalsFor({ product, agents = {}, signers = [] }) {
  const agentsTotal = agentsToArray(agents).reduce(
    (acc, agent) => acc + agent.amount,
    0,
  );

  const { signerTotal } = signers.reduce(
    (acc, signer) => {
      const { splitAmount, influencerCreated } = signer;

      acc.signerTotal += splitAmount;

      if (influencerCreated) {
        acc.created.push(new Date(influencerCreated));
      }

      return acc;
    },
    { signerTotal: 0, created: [] },
  );

  const subtotal = agentsTotal + signerTotal;

  if (product.fixedBump) {
    return { bump: round(product.productBump), total: subtotal };
  }

  const bumpPercentage = product.bumpPercentage ?? 0;
  const additionalBumpAmount = product.additionalBumpAmount ?? 0;
  const signingTypeBumpAmount = signingTypeBumpMap[product.signedType] ?? 0;

  const result = { bump: 0, total: subtotal };

  result.bump = round(subtotal / (1 - bumpPercentage) - subtotal);
  result.bump += additionalBumpAmount + signingTypeBumpAmount;

  return result;

  function round(n) {
    return Math.ceil(n / 100) * 100;
  }
}

export async function uploadImage({
  imageFile,
  cropSettings,
  product,
  setProduct,
  setState,
}) {
  if (!imageFile) return;

  try {
    const params = new URLSearchParams({ bn: "productImage" });

    const response = await window.fetch(`/api/user/imageUpload?${params}`, {
      method: "POST",
      body: imageFile,
      headers: {
        "Content-Type": imageFile.type,
        "X-Image-Crop-Width": cropSettings.width,
        "X-Image-Crop-Height": cropSettings.height,
        "X-Image-Crop-X": cropSettings.x,
        "X-Image-Crop-Y": cropSettings.y,
      },
    });

    if (!response.headers.get("content-type")?.includes("application/json")) {
      throw new Error("Something went wrong, please try again");
    }

    const json = await response.json();

    if (json.success) {
      setProduct(D.merge(json.image));
      setState(
        D.merge({
          isLoading: false,
          errorMessage: null,
          hasError: false,
          hasUnsavedChanges: false,
        }),
      );
    } else {
      setProduct(D.set("imageUrl", product.imageUrl));
      setState(
        D.merge({
          errorMessage: json.error.message,
          hasError: true,
          isLoading: false,
          hasUnsavedChanges: false,
        }),
      );
    }
  } catch (err) {
    setProduct(D.set("imageUrl", product.imageUrl));
    setState(
      D.merge({
        errorMessage: err.message,
        hasError: true,
        isLoading: false,
        hasUnsavedChanges: false,
      }),
    );
  }
}

export function aspectRatioFor({ productTypeAspectRatio }) {
  if (!productTypeAspectRatio) {
    return null;
  }

  return {
    portrait: productTypeAspectRatio,
    landscape: 1 / productTypeAspectRatio,
  };
}

export async function readFileToUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsDataURL(file);
  });
}
